import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

  // function toggleMenu() {
  //   const menu = document.getElementById("mobileNavContainer")!;
  //   menu.classList.toggle("open-menu");
  //   console.log("pressed");
  // }

  return (
    <div
      className="app-div"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/img/tempbg.png"})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
        width: "100vw",
        overflow: "scroll",
      }}
    >
      <div className="mobile-menu">
        <img className="nav-logo" src="/img/logo.png" alt="" />

        <div
          className="social-icons"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="https://twitter.com/CorruptCamelNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img className="nav-social" src="/icons/twitterIcon.png" alt="" />
          </a>
          <a
            href="https://discord.gg/h8MZ7dsPtC"
            target="_blank"
            rel="noreferrer"
          >
            <img className="nav-social" src="/icons/discordIcon.png" alt="" />
          </a>
          <a
            href="https://www.instagram.com/corrupt_camel_collective/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="nav-social" src="/icons/instagramIcon.png" alt="" />
          </a>
        </div>
      </div>
      <nav>
        <div className="nav-container">
          <img className="nav-logo" src="/img/logo.png" alt="" />
          {/* <a className="hide-800" href="/#link1">
            Link 1
          </a>
          <a className="hide-800" href="/#link2">
            Link 2
          </a>
          <a className="hide-800" href="/#link3">
            Link 3
          </a>
          <a className="hide-800" href="/#link4">
            Link 4
          </a> */}
          <div className="social-icons hide-800">
            <a
              href="https://twitter.com/CorruptCamelNFT"
              target="_blank"
              rel="noreferrer"
            >
              <img className="nav-social" src="/icons/twitterIcon.png" alt="" />
            </a>
            <a
              href="https://discord.gg/h8MZ7dsPtC"
              target="_blank"
              rel="noreferrer"
            >
              <img className="nav-social" src="/icons/discordIcon.png" alt="" />
            </a>
            <a
              href="https://www.instagram.com/corrupt_camel_collective/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="nav-social"
                src="/icons/instagramIcon.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </nav>
      <div className="content-wrapper">
        <header className="card" id="link1">
          <div className="heading-text">
            <h3 className="text-secondary-color">
              We’re off to the races again...
            </h3>
            <h1 className="title">The Corrupt Camel Collective</h1>
          </div>

          <div className="content-text-dapp-wrapper">
            <div className="content-text">
              <p className="text-secondary-color">
                NFTs for the Misft in all of us. Be yourself, without judgement,
                in the NFT space.
                <br />
                <br />6 Hand-Drawn NFT collections, including 2 FREE mints!
                <br />
                <br />
                Passionate, fully doxed team with over 10 years of finance,
                programming, and creative experience. Over three months were
                spent creating the first two collections.
                <br />
                <br /> 15 Legendary 1 of 1 airdrops to random holders.
                <br />
                <br /> 10% of paid mint will go to a charity chosen by our
                community.
                <br />
                <br /> 10% of paid mint and 10% of secondary sales will go into
                the community wallet (The Cage) used to give back to all
                holders.
                <br />
                <br /> Free nft airdrops to holders.
              </p>
            </div>
            <div className="content-mint-dapp">
              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      <Minter
                        candyMachineId={candyMachineId}
                        connection={connection}
                        startDate={startDateSeed}
                        txTimeout={txTimeout}
                        rpcHost={rpcHost}
                      />
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>
            </div>
          </div>
        </header>

        {/* <div id="link2" className="container">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac velit
          aliquet, semper sapien sed, ornare augue. Phasellus sed velit
          interdum, sagittis metus quis, facilisis lectus. Cras sollicitudin
          purus at magna eleifend maximus. Nulla nec nulla in nunc maximus
          viverra in at mauris. Fusce sodales dolor nisi, et vehicula orci porta
          id. In placerat nunc sed erat lacinia tincidunt. Interdum et malesuada
          fames ac ante ipsum primis in faucibus. Vestibulum commodo eget metus
          vitae tempus. Aliquam pharetra mi at efficitur accumsan. Curabitur
          venenatis libero a ex porttitor, at auctor turpis hendrerit. Nam
          commodo, risus non consequat pretium, erat ante auctor purus, a cursus
          dolor erat at velit. Maecenas dignissim, dolor sed laoreet aliquam,
          tortor lacus faucibus urna, eget mattis massa sem ac dui. Nam semper
          hendrerit interdum. Etiam at dictum nisi.
        </div> */}

        {/* <div id="link3" className="container card">
          <h1 className="pb-3">Lorem ipsum</h1>
          <div id="link4" className="container faq">
            <h1 style={{ padding: "0 0 24px 0" }}>FAQ</h1>
            <div>
              <h4>Lorem ipsum?</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse id metus id mauris tincidunt posuere. Vivamus neque
                odio, imperdiet vitae.
              </p>

              <hr />
            </div>

            <div>
              <h4>Lorem ipsum?</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse id metus id mauris tincidunt posuere. Vivamus neque
                odio, imperdiet vitae.
              </p>

              <hr />
            </div>

            <div>
              <h4>Lorem ipsum?</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse id metus id mauris tincidunt posuere. Vivamus neque
                odio, imperdiet vitae.
              </p>

              <hr />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default App;
